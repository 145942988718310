import Box              from '@mui/material/Box';
import TextField        from '@mui/material/TextField';
import React            from 'react';
import {_t}             from 'shared/lib/i18n/I18n';
import EditorSemaphore  from 'shared/model/EditorSemaphoreModel';
import {ClearableSmart} from 'shared/ui/form/Clearable';
import {SysColors}      from 'shared/ui/form/InputTypes';
import {TextInputType}  from 'shared/ui/form/TextInputType';

export const TextInput = (
    {
        errorVal,
        onClear,
        value,
        multiline,
        maxRows,
        minRows,
        label,
        onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {},
        onBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {},
        required,
        disabled,
        type = 'text',
        variant = 'standard',
        autoComplete = undefined,
        sx,
        sxInput,
        textAlign = 'left'

    }: TextInputType) => {

    if (disabled === undefined) {
        disabled = EditorSemaphore.isProcessing;
    }

    return <>
        <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
            <Box sx={{width: '100%'}}>
                <TextField
                    disabled={disabled}
                    required={required}
                    error={!!errorVal}
                    helperText={errorVal ? _t(errorVal) : ' '}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label={_t(label)}
                    multiline={multiline}
                    maxRows={maxRows}
                    minRows={minRows}
                    fullWidth={true}
                    variant={variant}
                    margin='normal'
                    sx={{...sx, mt: 0, mb: 0}}
                    type={type}
                    autoComplete={autoComplete}
                    // slotProps={{
                    //     input: {
                    //         inputProps: {
                    //             sx   : {...sxInput},
                    //             style: {
                    //                 textAlign: textAlign,
                    //
                    //             },
                    //         }
                    //     }
                    // }}
                    InputProps={{
                        inputProps: {
                            sx   : {...sxInput},
                            style: {
                                textAlign: textAlign,
                            },
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>

            {onClear ?
                <Box sx={{alignItems: 'self-end'}}>
                    <ClearableSmart
                        onClear={onClear}
                        value={value}
                        color={errorVal ? SysColors.Error : SysColors.Default}
                    />
                </Box> : null
            }

        </Box>
    </>;
};
