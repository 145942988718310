import React                                                                                                                                     from 'react';
import {_t}                                                                                                                                      from 'shared/lib/i18n/I18n';
import {ElementMap, Handbook, HandbookElement, HandbookElementComponent, HandbookKey, HandbookKeyList, HandbookNormalised, HandbookVal, hbIndex} from 'shared/types/Handbook';

export const emptyHb: Handbook = buildHbSingle('', '')

export function buildHbSingle(
    key: HandbookKey,
    val: HandbookVal
): Handbook {
    return [buildHbElement(key, val)]
}

export function buildHbElement(
    key: HandbookKey,
    val: HandbookVal
): HandbookElement {
    return {
        [hbIndex.k]: key,
        [hbIndex.v]: val
    };
}

export const handbookToElements = (data: HandbookElement[], mapper: ElementMap): HandbookElementComponent[] => {
    let withIcon: any = [];
    Object.entries(data).forEach(([i, obj]) => {
        withIcon.push({
            [hbIndex.k]: obj[hbIndex.k],
            [hbIndex.v]: <>{mapper?.['' + obj[hbIndex.k]] ?? ''} {_t(obj[hbIndex.v])}</>
        });
    });

    return withIcon;
};

export const buildHandbook = (data: any, k: string, v: string): Handbook => {

    let hb: Handbook | any = [];
    Object.entries(data).forEach(([, item]: any) => (
        hb.push({
            [hbIndex.k]: item[k],
            [hbIndex.v]: item[v]
        })
    ));

    return hb;
};

export const buildHandbookFromObj = (data: any): any => {

    if (!data) {
        return [];
    }

    let hb: Handbook | any = [];
    Object.entries(data).forEach(([i, value]: any) => (
        hb.push({
            [hbIndex.k]: i,
            [hbIndex.v]: value
        })
    ));

    return hb;
};

export const extractKeys = (hb: Handbook): HandbookKeyList => {
    return hb.map((obj) => obj[hbIndex.k]);
};

export const extractNumberKeys = (hb: Handbook): Array<number> =>
    !hb || !hb.length ?
        [] :
        hb
            .map((obj) => parseInt(obj[hbIndex.k] + ''))
            .sort((a, b) => a - b);


export function kvNormalise(data: Handbook): HandbookNormalised {
    let dataMap: HandbookNormalised = {};
    Object.entries(data).forEach(([i, obj]) =>
        dataMap[obj[hbIndex.k]] = obj[hbIndex.v]);

    return dataMap;
}

export function sortKvByV(handbook: Handbook): Handbook {
    let hb: Handbook = [];
    const sortedByValue = Object.entries(handbook).sort(function(a: any, b: any) {
        return ('' + a[1].v).localeCompare(b[1].v);
    });

    sortedByValue.forEach((i, v) => {
        hb.push(i[1]);
    });

    return hb;
}

